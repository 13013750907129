import { useContext, useEffect, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { useForm } from "react-form";
import { EModal, ModalContext } from "../../contexts/ModalProvider";
import mutation from "../../network/mutation";
import { RequestManager } from "../../network/queries";
import InputElement from "../form/InputElement";
import Notification, { ENotificationStatus } from "../Notification";
import Modal from "../Modal";
import { NotificationMessage } from "./types";

const UpdateTenantModal = (): JSX.Element => {
  const [tenant, setTenant] = useState<any>(undefined);
  const manager = new RequestManager();

  const { clearValues, getModalStatus, getModalValue } =
    useContext(ModalContext);

  const modalValue = getModalValue(EModal.UpdateTenant);
  const { execute } = useAsync(manager.getTenant, [modalValue?.classifier]);
  const [notification, setNotification] = useState<NotificationMessage>({
    active: false,
  });

  const modalStatus = getModalStatus(EModal.UpdateTenant);
  const confirmButton = "Update Tenant";
  const mutateUpdateTenant = useAsyncCallback(mutation.updateTenant);

  useEffect(() => {
    execute(modalValue?.classifier).then((response) => {
      if (response?.data) {
        setTenant(response.data);
      }
    });
  }, [execute, modalValue?.classifier]);

  useEffect(() => {
    if (notification?.active === true) {
      setTimeout(() => {
        setNotification({ active: false });
      }, 5000);
    }
  }, [notification?.active]);

  const { Form, values, reset } = useForm({});

  const updateTenant = async () => {
    try {
      await mutateUpdateTenant.execute(modalValue?.classifier, {
        details: {
          name: values?.name,
          active: values?.active,
        },
        report: {
          url: values?.url,
          username: values?.tenant_username,
          password: values?.tenant_password,
          client_code: values?.client_code,
        },
      });
      clearValues();
      setNotification({
        title: `Tenant: ${modalValue.classifier}`,
        message:
          "The tenant has been updated successfully. Please test to verify that the tenant works correctly.",
        status: ENotificationStatus.Completed,
        active: true,
      });
      reset();
    } catch (error) {
      clearValues();
      setNotification({
        title: "Unable to update tenant",
        message:
          "There has been an issue updating the tenant, please contact your administrator.",
        status: ENotificationStatus.Failed,
        active: true,
      });
    }
  };

  const resetModal = () => {
    reset();
    clearValues();
  };

  return (
    <>
      <Form>
        <Modal
          open={modalStatus}
          onClick={updateTenant}
          onClose={resetModal}
          confirmButton={confirmButton}
        >
          <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Update Tenant
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Update a specific tenant with the related attributes
                </p>
                <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                  <InputElement
                    field="name"
                    name="Name"
                    type="text"
                    defaultValue={modalValue?.name}
                  ></InputElement>
                  <InputElement
                    field="classifier"
                    name="Classifier"
                    type="text"
                    value={modalValue?.classifier}
                  />
                  <InputElement
                    field="url"
                    name="URL"
                    type="text"
                    defaultValue={tenant?.url}
                  />
                  <InputElement
                    field="active"
                    name="Active"
                    type="text"
                    value={tenant?.active}
                  />
                  <InputElement
                    field="tenant_username"
                    name="Username"
                    type="text"
                  />
                  <InputElement
                    field="tenant_password"
                    name="Password"
                    type="password"
                  />
                  <InputElement
                    field="clientcode"
                    name="Client Code"
                    type="text"
                    defaultValue={modalValue?.client_code || "Unknown"}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Form>
      <Notification
        message={notification?.message}
        active={notification?.active}
        title={notification?.title}
        status={notification?.status}
        callback={() => setNotification({ active: false })}
      />
    </>
  );
};

export default UpdateTenantModal;
