/* eslint-disable import/no-anonymous-default-export */
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Loader } from './Loader';
import { useErrorBoundaryContext } from '../app/ErrorBoundary';

type Props = {
  children: JSX.Element;
};

export default ({ children }: Props) => {
  const { error, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { trigger401Error } = useErrorBoundaryContext();

  useEffect(() => {
    if (!error && !isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [error, isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (error) {
      trigger401Error(error.message);
    }
  }, [error, trigger401Error]);

  return isLoading ? <Loader /> : isAuthenticated ? children : null;
}
