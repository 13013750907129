import { useContext, useEffect, useState, useCallback } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useForm } from "react-form";

import Modal from "../Modal";
import mutation, { Tenant } from "../../network/mutation";
import InputElement from "../form/InputElement";
import { EModal, ModalContext } from "../../contexts/ModalProvider";
import Notification, { ENotificationStatus } from "../Notification";
import { NotificationMessage } from "./types";

const CreateTenantModal = ({ title }: any): JSX.Element => {
  const { clearValues, getModalStatus } = useContext(ModalContext);
  const [notification, setNotification] = useState<NotificationMessage>({
    active: false,
  });

  const mutateCreateTenant = useAsyncCallback(mutation.createTenant);
  const modalStatus = getModalStatus(EModal.CreateTenant);

  const {
    Form,
    meta: { canSubmit },
    values,
  } = useForm({});

  const createTenant = useCallback(async () => {
    const pureValues = values as unknown as Tenant;
    const { url, username, password } = pureValues;
    try {
      if (
        ![typeof url, typeof username, typeof password].includes("undefined")
      ) {
        await mutateCreateTenant.execute(pureValues);
        clearValues();
        setNotification({
          title: `Tenant: ${pureValues.classifier}`,
          message: "The tenant has been created successfully.",
          status: ENotificationStatus.Completed,
          active: true,
        });
      } else {
        clearValues();
        setNotification({
          title: "Unable to create tenant",
          message:
            "You have not entered a field in one of the required fields, please try again.",
          status: ENotificationStatus.Failed,
          active: true,
        });
      }
    } catch (error) {
      clearValues();
      setNotification({
        title: "Unable to create tenant",
        message:
          "There has been an issue creating the tenant, please contact your administrator.",
        status: ENotificationStatus.Failed,
        active: true,
      });
    }
  }, [clearValues, mutateCreateTenant, values]);

  useEffect(() => {
    if (notification?.active === true) {
      setTimeout(() => {
        setNotification({ active: false });
      }, 5000);
    }
  }, [notification?.active]);

  return (
    <>
      <Form>
        <Modal
          open={modalStatus}
          canSubmit={canSubmit}
          onClick={() => createTenant()}
          onClose={() => clearValues()}
          confirmButton="Create Tenant"
        >
          <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Create a tenant that will be proxied by Matrix Absence
                  Management via the Serverless API.
                </p>
              </div>
              <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <InputElement field="name" name="Name" type="text" />
                <InputElement
                  field={"classifier"}
                  name="Classifier"
                  type="text"
                />
                <InputElement
                  field={"clientcode"}
                  name="Client Code"
                  type="number"
                />
                <InputElement field={"url"} name={"URL"} type="text" />
                <InputElement
                  field={"username"}
                  name={"Username"}
                  type="text"
                />
                <InputElement
                  field={"password"}
                  name={"Password"}
                  type="text"
                />
              </div>
              <br />
            </div>
          </div>
        </Modal>
      </Form>
      <Notification
        message={notification?.message}
        active={notification?.active}
        title={notification?.title}
        status={notification?.status}
        callback={() => setNotification({ active: false })}
      />
    </>
  );
};

export default CreateTenantModal;
