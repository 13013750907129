/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

const { REACT_APP_IDENTITY_API } = process.env;

const getAccessToken = async () => {
  const { data } = await axios.get(
    `${REACT_APP_IDENTITY_API}/v1/identity/client-credentials`
  );
  return data;
};

export default {
  getAccessToken,
};
