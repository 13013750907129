/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

const { REACT_APP_API_HOST } = process.env;

export type Tenant = {
  name: string;
  url: string;
  type?: string;
  classifier: string;
  username: string;
  password: string;
  clientcode: number;
};

export type Report = {
  url: string;
  username: string;
  password: string;
};

async function createTenant(tenant: Tenant) {
  const { data } = await axios.post(
    `${REACT_APP_API_HOST}/v1/tenant/${tenant.classifier}`,
    {
      name: tenant.name,
      url: tenant.url,
      type: "TEST",
      credentials: {
        username: tenant.username,
        password: tenant.password,
      },
      client_code: tenant.clientcode,
    }
  );

  return data;
}

async function updateTenant(classifier: string, payload: any) {
  const { data } = await axios.put(
    `${REACT_APP_API_HOST}/v1/tenant/${classifier}`,
    payload
  );

  return data;
}

export default {
  createTenant,
  updateTenant,
};
