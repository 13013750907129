/* eslint-disable import/no-anonymous-default-export */
import axios, { AxiosHeaders } from "axios";
import oauth2 from "../oauth2";
import {
  GetTenantResponse,
  UpdateTenantRequest,
  CreateTenantRequest,
  QueryTenantRequest,
  QueryTenantResponse,
  GetTenantsResponse,
} from "./types";

const { REACT_APP_API_HOST, REACT_APP_IDENTITY_HOST } = process.env;

export class RequestManager {
  constructor() {
    if (typeof REACT_APP_IDENTITY_HOST !== "undefined") {
      oauth2.getAccessToken().then((response) => {
        const { data } = response;
        const { access_token } = data;

        axios.interceptors.request.use((request) => {
          const headers = request?.headers as AxiosHeaders;
          headers.set("Authorization", `Bearer ${access_token}`);

          return request;
        });
      });
    }
  }

  async getTenant(tenant: string): Promise<GetTenantResponse | undefined> {
    if (typeof tenant !== "undefined") {
      const { data } = await axios.get(
        `${REACT_APP_API_HOST}/v1/tenant/${tenant}`
      );
      return data;
    }
  }

  async getTenants(): Promise<GetTenantsResponse> {
    const { data } = await axios.get(`${REACT_APP_API_HOST}/v1/tenant`);
    return data;
  }

  async updateTenant(tenant: string, params: UpdateTenantRequest) {
    const { data } = await axios.put(
      `${REACT_APP_API_HOST}/v1/tenant/${tenant}`,
      params
    );
    return data;
  }

  async createTenant(tenant: string, params: CreateTenantRequest) {
    const { data } = await axios.post(
      `${REACT_APP_API_HOST}/v1/tenant/${tenant}`,
      params
    );

    return data;
  }

  async queryTenants(
    params?: QueryTenantRequest
  ): Promise<QueryTenantResponse[]> {
    const { data } = await axios.post(
      `${REACT_APP_API_HOST}/v1/tenant/query`,
      params
    );

    return data;
  }
}
