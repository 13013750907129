import { Component, createContext, ReactNode, useContext } from 'react';
import ErrorLayout from './ErrorLayout';

type ErrorBoundaryContextType = {
  triggerError(data: Pick<State, 'code' | 'message'>): void;
  trigger401Error(message?: string): void;
  trigger403Error(message?: string): void;
};

const ErrorBoundaryContext = createContext<ErrorBoundaryContextType>(null!);

export function useErrorBoundaryContext() {
  return useContext(ErrorBoundaryContext);
}

type Props = {
  children: ReactNode;
};

type State = {
  code?: number;
  message: string;
  hasError: boolean;
  contextValue: ErrorBoundaryContextType;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      code: undefined,
      message: '',
      hasError: false,
      contextValue: {
        triggerError: this.triggerError.bind(this),
        trigger401Error: this.trigger401Error.bind(this),
        trigger403Error: this.trigger403Error.bind(this),
      },
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { message: error.message, hasError: true };
  }

  triggerError({ code, message }: Pick<State, 'code' | 'message'>) {
    this.setState({ code, message, hasError: true });
  }

  trigger401Error(message = 'Unauthorized') {
    this.triggerError({ code: 401, message });
  }

  trigger403Error(message = 'Access Denied') {
    this.triggerError({ code: 403, message });
  }

  render() {
    return (
      <ErrorBoundaryContext.Provider value={this.state.contextValue}>
        {!this.state.hasError ? (
          this.props.children
        ) : (
          <ErrorLayout code={this.state.code} message={this.state.message} />
        )}
      </ErrorBoundaryContext.Provider>
    );
  }
}
