/* eslint-disable import/no-anonymous-default-export */
import { ElementType, useMemo } from 'react';
import { ArrowPathIcon, HomeIcon } from '@heroicons/react/20/solid';

type Props = {
  goHome?: boolean;
  code?: number;
  message?: string;
  text?: string;
};

export default ({ goHome, code, message, text }: Props) => {
  const { shouldGoHome, heading, description } = useMemo(() => {
    let shouldGoHome = goHome || false;
    let heading = '';
    let description = '';

    switch (code) {
      case 403:
        heading = message || '';
        description = "You don't have permission to access requested page.";
        shouldGoHome = true;
        break;

      case 404:
        heading = 'Page not found';
        description = "This page doesn't exist or was removed";
        break;

      default:
        heading = message || 'Something went wrong';
        description = text || 'Please try again';
    }

    if (heading.length > 60) {
      description = `${heading}\n${description}`;
      heading = `${heading.slice(0, 60)}...`;
    }

    return { shouldGoHome, heading, description };
  }, [goHome, code, message, text]);

  return (
    <div
      data-testid="error-layout"
      className="fixed inset-0 flex items-center justify-center"
    >
      <div className="max-w-3xl p-5 text-gray-800 md:flex">
        <div className="font-bold text-indigo-500">
          <div className="text-5xl">{code || 'Ooops'}</div>
        </div>
        <div className="my-8 h-[1px] w-[110px] bg-gray-300 md:mx-8 md:my-0 md:h-20 md:w-[1px]" />
        <div>
          <h1 className="mb-6 text-3xl font-bold sm:text-4xl">{heading}</h1>
          {description.split(/\n/).map((text, index) => (
            <div key={index} className="mt-4 text-xl text-gray-400">
              {text}
            </div>
          ))}
          {shouldGoHome ? <HomeButton /> : <ReloadButton />}
        </div>
      </div>
    </div>
  );
}

function ReloadButton() {
  return (
    <Button
      text="Refresh Page"
      Icon={ArrowPathIcon}
      onClick={() => window.location.reload()}
    />
  );
}

function HomeButton() {
  return (
    <Button
      text="Go Home"
      Icon={HomeIcon}
      onClick={() => (window.location.href = '/')}
    />
  );
}

function Button({
  text,
  Icon,
  onClick,
}: {
  text: string;
  Icon: ElementType;
  onClick(): void;
}) {
  return (
    <button
      className={`
        mt-8 inline-flex items-center rounded-md border border-transparent bg-indigo-500 px-5 
        py-3 text-white shadow-sm hover:bg-indigo-600 hover:bg-indigo-700 
        focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2
      `}
      onClick={onClick}
    >
      <Icon className="mr-2 h-5 w-5" aria-hidden="true" />
      <span className="-mt-0.5">{text}</span>
    </button>
  );
}
