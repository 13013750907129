import React from "react";
import { useAsync } from "react-async-hook";
import Statistics from "../../../components/Statistics";
import ApplicationTable from "../../../components/tables/ApplicationTable";
import Card from "../../../layout/Card";
import { RequestManager } from "../../../network/queries";


const ApplicationIndex: React.FC = () => {
  const manager = new RequestManager()
  const { result, execute } = useAsync(manager.getTenants, []);

  return (
    <React.Fragment>
      <Statistics value={result?.data} />
      <br />
      <Card>
        <ApplicationTable tenants={result?.data} />
      </Card>
    </React.Fragment>
  );
};

export default ApplicationIndex;
