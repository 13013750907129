import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Dashboard from "./screens/Application/Dashboard";
import ApplicationIndex from "./screens/Application";
import ModalProvider from "./contexts/ModalProvider";
import { AuthProvider } from "./auth0/Provider";

function App() {
  return (
    <AuthProvider>
      <ModalProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <ApplicationIndex>
                  <Dashboard />
                </ApplicationIndex>
              }
            ></Route>
            <Route
              path="/dashboard"
              element={
                <ApplicationIndex>
                  <Dashboard />
                </ApplicationIndex>
              }
            />
            <Route
              path="/audit"
              element={
                <ApplicationIndex>
                </ApplicationIndex>
              }
            />
          </Routes>
        </BrowserRouter>
      </ModalProvider>
    </AuthProvider>
  );
}

export default App;
