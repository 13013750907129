import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import Statistics from "../../components/Statistics";
import Table from "../../components/tables/ApplicationTable";
import Card from "../../layout/Card";
import StackedLayout from "../../layout/StackedLayout";

const ApplicationIndex = ({ children }: any): JSX.Element => {

  const { error, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [error, isLoading, isAuthenticated, loginWithRedirect]);

  return <StackedLayout>{children}</StackedLayout>;
};

export default ApplicationIndex;
