/* This example requires Tailwind CSS v2.0+ */
import { useMemo } from "react";

const Statistics = ({ value: item }: any) => {
  const stats = useMemo(
    () => [
      {
        name: "Total Active Tenants",
        stat: item?.length,
        previousStat: "0",
        change: "0",
      },
      {
        name: "All Tenants",
        stat: item?.length,
        previousStat: "0",
        change: "0",
      },
      {
        name: "System Errors",
        stat: item?.data?.files || 0,
        previousStat: "0",
        change: "0",
      },
    ],
    [item?.data?.files, item?.length]
  );

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Statistics;
