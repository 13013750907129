import { Auth0Provider } from "@auth0/auth0-react";
import AuthManager from "./Manager";

type Props = {
  children: JSX.Element;
};

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_CLIENT_ID } = process.env;

export function AuthProvider({ children }: Props) {
  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN as string}
      clientId={REACT_APP_CLIENT_ID as string}
      redirectUri={window.location.origin}
    >
      <AuthManager>{children}</AuthManager>
    </Auth0Provider>
  );
}
