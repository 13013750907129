import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import CreateDirectoryModal from "../components/modals/CreateTenantModal";
import { EModal, ModalContext } from "../contexts/ModalProvider";

type NavigationItem = {
  current: boolean;
  href: string;
  name: string;
};

const initialNavigation: NavigationItem[] = [
  { name: "Dashboard", href: "/", current: false },
  { name: "Audit", href: "/audit", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Button: React.FC<any> = ({ children, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      {children}
    </button>
  );
};

const StackedLayoutHeader: React.FC<any> = ({ navigation }: any) => {
  const location = useLocation();
  const { activateModal } = useContext(ModalContext);

  const renderResouceComponent = () => {
    const currentNavIndex = navigation.findIndex(
      (nav: any) => nav.href === location.pathname
    );

    const currentNav = navigation[currentNavIndex];
    if (typeof currentNav !== "undefined") {
      const { name } = currentNav;
      switch (name) {
        case "Tenants":
          return (
            <Button onClick={() => activateModal(EModal.CreateTenant)}>
              Create Tenant
            </Button>
          );
      }
    }
  };

  const currentNavigation = useCallback(() => {
    const currentNavIndex = navigation.findIndex(
      (nav: any) => nav.current === true
    );
    const currentNav = navigation[currentNavIndex];

    return currentNav;
  }, [navigation]);

  return (
    <header className="bg-white shadow-sm">
      <div className="mx-auto grid max-w-7xl grid-cols-2 grid-rows-1 gap-4 py-4 px-4 sm:px-6 lg:px-8">
        <h1 className="text-lg font-semibold leading-6 text-gray-900">
          {currentNavigation()?.name || "Neptune"}
        </h1>
        <div className="grid place-items-end"> {renderResouceComponent()}</div>
      </div>
    </header>
  );
};

const StackedLayout: React.FC<any> = ({ children }: any) => {
  const location = useLocation();
  const [navigation, setNavigation] = useState(initialNavigation);

  const { user, logout } = useAuth0();

  const updateNavigation = useCallback(() => {
    const currentNavIndex = navigation.findIndex((nav) => nav.current === true);
    const updatedNavIndex = navigation.findIndex(
      (nav) => nav.href === location.pathname
    );

    const currentNav = navigation[currentNavIndex];
    const updatedNav = navigation[updatedNavIndex];

    if (
      typeof currentNav !== "undefined" &&
      typeof updatedNav !== "undefined"
    ) {
      currentNav.current = false;
      updatedNav.current = true;

      setNavigation(navigation);
    }
  }, [location.pathname, navigation]);

  const logOutUser = useCallback(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (
      ["/links", "/clients", "/directories", "/"].includes(location.pathname)
    ) {
      const navIndex = navigation.findIndex(
        (nav) => nav.href === location.pathname
      );

      const currentNav = navigation[navIndex];
      currentNav.current = true;
      setNavigation(navigation);
    }
  }, [location.pathname, navigation]);

  useEffect(() => {
    if (["/links", "/clients", "/directories", "/"].includes(location.pathname))
      updateNavigation();
  }, [location.pathname, navigation, updateNavigation]);

  const userNavigation = [{ name: "Sign out", callback: logOutUser }];

  return (
    <>
      <CreateDirectoryModal title="Create Directory" />
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user?.picture}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                    onClick={item.callback}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user?.picture}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">
                        {user?.given_name}
                      </div>
                      <div className="text-sm font-medium text-gray-400">
                        {user?.family_name}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="button"
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        onClick={item.callback}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <StackedLayoutHeader navigation={navigation} />
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            {children}
          </div>
        </main>
      </div>
    </>
  );
};

export default StackedLayout;
