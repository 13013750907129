import { forwardRef } from "react";
import { useField, splitFormProps } from "react-form";

const InputField = forwardRef((props: any, ref) => {
  const [field, options, rest] = splitFormProps(props);
  const { getInputProps } = useField(field, options);

  return (
    <div className="mt-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="username"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {rest.name}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <div className="flex max-w-lg rounded-md shadow-sm">
          <input
            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            {...getInputProps({ ref, ...rest })}
          />
        </div>
      </div>
    </div>
  );
});

export default InputField;
